<template>
	<div id="paike_index">
		<index>
			<div class="content">
				<el-breadcrumb>
					<el-breadcrumb-item>教务</el-breadcrumb-item>
					<el-breadcrumb-item><a style="color: #000000;">岗位安排</a></el-breadcrumb-item>
				</el-breadcrumb>
				<div class="nav-tab-bar-box u-f-item u-f-jsb">
					<div class="nav-tab-bar u-f-item">
						<div
							class="font_14 nav-tab-item"
							@click="twacherTap(index)"
							:class="index == teacherIndex ? 'active' : ''"
							v-for="(item, index) in teacherMeun"
							:key="index"
						>
							{{ item.name }}
						</div>
					</div>
				</div>
				<template v-if="teacherIndex==0">
					<div class="shaixuan u-f-item">
						<el-select v-model="schoolId" size="small" style="width: 7.29vw;margin-right: 0.52vw;" @change="schoolChange">
							<el-option v-for="item in schooleList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
						<el-select v-model="yearId" size="small" style="width: 7.29vw;margin-right: 0.52vw;" @change="yearChange">
							<el-option v-for="item in yearList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
						<el-select v-model="xueqiId" size="small" style="width: 7.29vw;margin-right: 0.52vw;">
							<el-option v-for="item in xueqiList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
						<el-select v-model="gradeId" size="small" style="width: 7.29vw;margin-right: 0.52vw;" @change="gradeChange">
							<el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					
					<div class="table-box" v-if="gradeId">
						<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
							<div class="font_blod">共{{total}}条数据</div>
							<div class="u-f-item">
								<el-input size="small" v-model="keyword" placeholder="请输入名称搜索" style="margin-right: 10px;" @change="sousuo"></el-input>
								<el-button size="small" @click="add">新增行政班岗位</el-button>
								<el-button size="small" @click="mergeShow=true">新增走班岗位</el-button>
								<el-button size="small">批量录入</el-button>
								<el-button size="small" type="primary">导出</el-button>
							</div>
						</div>
						<el-table :data="jobList" style="width: 100%;" height="85%" @selection-change="handleSelectionChange">
							<el-table-column type="selection" width="55"></el-table-column>
							<el-table-column type="index" label="序号" width="120"></el-table-column>
							<el-table-column prop="name" label="名称"></el-table-column>
							<el-table-column label="参与班级">
								<template slot-scope="scope">
									<span>全年级</span>
								</template>
							</el-table-column>
							<el-table-column prop="rule" label="类型">
								<template slot-scope="scope">
									<el-tag>{{scope.row.job_type_text}}</el-tag>
								</template>
							</el-table-column>
							<el-table-column label="状态">
								<template slot-scope="scope">
									<span v-if="scope.row.is_config==1">配置中</span>
									<span v-if="scope.row.is_config==2">配置完成</span>
								</template>
							</el-table-column>
							<el-table-column label="操作" width="100">
								<template slot-scope="scope">
									<el-button @click="editor(scope.row)" type="text" size="small">编辑</el-button>
									<span style="color: #E7E7E7;">|</span>
									<el-button @click="deploy(scope.row.id,scope.row.name)" type="text" size="small">配置</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="u-f-item">
							<el-button @click="delstaton" type="danger" size="small">删除</el-button>
						</div>
						<!-- 编辑 -->
						<el-dialog title="编辑行政班岗位" :visible.sync="editorShow" width="580px"  :before-close="handleClose">
							<div class="editorBox">
								<div class="u-f-item div">
									<div class="input-lebal u-f1" style="text-align: right;">
										岗位名称
										<span>*</span>
									</div>
									<div class="u-f4"><el-input type="text" placeholder="请输入内容" v-model="jobname" maxlength="10" show-word-limit></el-input></div>
								</div>
								<div class="u-f div">
									<div class="input-lebal u-f1" style="text-align: right;">
										确认标准学科
										<span>*</span>
									</div>
									<div class="u-f4">
										<div class="weeks">
											<div v-for="(item, index) in GradeDisciplineList" :key="index" :class="item.checked?'active':''" @click="weeksChange(index)">{{ item.name }}</div>
										</div>
									</div>
								</div>
							</div>
							<span slot="footer" class="dialog-footer">
								<el-button type="primary" plain @click="closeaddjob">取 消</el-button>
								<el-button type="primary" @click="submiteditJob">确 定</el-button>
							</span>
						</el-dialog>
						<!-- 新增 -->
						<el-dialog title="新增行政班岗位" :visible.sync="addShow" width="580px" :before-close="handleClose">
							<div class="editorBox">
								<div class="u-f-item div">
									<div class="input-lebal u-f1" style="text-align: right;">
										岗位名称
										<span>*</span>
									</div>
									<div class="u-f4"><el-input type="text" placeholder="请输入内容" v-model="jobname" maxlength="10" show-word-limit></el-input></div>
								</div>
								<div class="u-f div">
									<div class="input-lebal u-f1" style="text-align: right;">
										确认标准学科
										<span>*</span>
									</div>
									<div class="u-f4">
										<div class="weeks">
											<div v-for="(item, index) in GradeDisciplineList" :key="index" :class="item.checked?'active':''" @click="weeksChange(index)">{{ item.name }}</div>
										</div>
									</div>
								</div>
							</div>
							<span slot="footer" class="dialog-footer">
								<el-button type="primary" plain @click="closeaddjob">取 消</el-button>
								<el-button type="primary" @click="submitaddJob">确 定</el-button>
							</span>
						</el-dialog>
					</div>
					<div class="table-box" v-else>
						<div class="u-f-item" style="padding: 15px 0;">
							岗位安排
						</div>
						<div style="height: 85%;" class="u-f-justify u-f-column">
							<img src="../../assets/image/nothing.png" style="width: 120px;">
							<div style="color: #A4A4A4;">暂无数据</div>
						</div>
					</div>
				</template>
				<!-- 岗位统计 -->
				<!-- <template v-if="teacherIndex==1">
					<div class="shaixuan u-f-item">
						<el-select v-model="type" size="small" style="width: 7.29vw;margin-right: 0.52vw;">
							<el-option label="演示版" value="shanghai"></el-option>
							<el-option label="2015学年" value="beijing"></el-option>
						</el-select>
						<el-select v-model="study_year" size="small" style="width: 7.29vw;margin-right: 0.52vw;">
							<el-option label="2014学年" value="shanghai"></el-option>
							<el-option label="2015学年" value="beijing"></el-option>
						</el-select>
						<el-select v-model="semester" size="small" style="width: 7.29vw;margin-right: 0.52vw;">
							<el-option label="上学期" value="shanghai"></el-option>
							<el-option label="下学期" value="beijing"></el-option>
						</el-select>
					</div>
					
					<div class="table-box">
						<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
							<div class="font_blod">共3条数据</div>
							<div class="u-f-item">
								<el-input size="small" v-model="keyword" placeholder="请输入编号搜索" style="margin-right: 10px;"></el-input>
								<el-button size="small" type="primary" @click="daochu">导出</el-button>
							</div>
						</div>
						<el-table :data="tableData2" style="width: 100%">
							<el-table-column type="selection" width="55"></el-table-column>
							<el-table-column prop="id" label="序号"></el-table-column>
							<el-table-column prop="name" label="姓名"></el-table-column>
							<el-table-column prop="grade" label="科组" :filters="[{ text: '上学期', value: '1' }, { text: '下学期', value: '2' }]"
						:filter-method="filterterm"></el-table-column>
							<el-table-column prop="rule" label="年级" :filters="[{ text: '上学期', value: '1' }, { text: '下学期', value: '2' }]"
						:filter-method="filterterm"></el-table-column>
							<el-table-column prop="time" label="班级" :filters="[{ text: '上学期', value: '1' }, { text: '下学期', value: '2' }]"
						:filter-method="filterterm"></el-table-column>
							<el-table-column prop="time" label="级长" sortable></el-table-column>
							<el-table-column prop="time" label="班主任" sortable></el-table-column>
							<el-table-column prop="time" label="科组长" sortable></el-table-column>
							<el-table-column prop="time" label="备长" sortable></el-table-column>
							<el-table-column prop="time" label="周课时" sortable></el-table-column>
							<el-table-column prop="time" label="选修" sortable></el-table-column>
							<el-table-column prop="time" label="社团" sortable></el-table-column>
							<el-table-column prop="time" label="代课记录" sortable></el-table-column>
							<el-table-column prop="time" label="其它记录" sortable></el-table-column>
							<el-table-column prop="time" label="监考时段" sortable></el-table-column>
						</el-table>
						<div class="u-f-right">
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page.sync="currentPage1"
								:page-size="10"
								layout="total, prev, pager, next"
								:total="3"
							></el-pagination>
						</div>
						
					</div>
				</template> -->
			</div>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			keyword: '',
			editorShow: false,
			addShow: false,
			currentPage1: 10,
			teacherMeun: [{ name: '岗位安排' }],//, { name: '岗位统计' }
			teacherIndex: 0,
			tableData: [
				{
					id: 1,
					name: '高一(14)班课程表',
					grade: '高一',
					rule: '高一年级排课规则',
					time: '2021-01-29 11:30',
					type: false
				},
				{
					id: 2,
					name: '高一(14)班课程表',
					grade: '高一',
					rule: '高一年级排课规则',
					time: '2021-01-29 11:30',
					type: true
				},
				{
					id: 3,
					name: '高一(14)班课程表',
					grade: '高一',
					rule: '高一年级排课规则',
					time: '2021-01-29 11:30',
					type: false
				}
			],
			name: '',
			jobname: '',
			addgradetext: '',
			CopyShow:false,
			addruleShow:false,
			week:['星期一','星期二','星期三','星期四','星期五','星期六','星期日'],
			jobList:[],
			total:0,
			schoolId:'',
			schooleList:[],
			yearId:'',
			yearList:[],
			xueqiList:[],
			xueqiId:'',
			gradeList:[],
			gradeId:'',
			grades:[],
			GradeDisciplineList:[],
			stationId:'',
			ids: []
		};
	},
	mounted() {
		this.schoolCampusIndex()
		this.getJobList()
		this.getSemesterList()
	},
	methods: {
		handleSelectionChange(val) {
			let arr = [];
			for(let i in val){
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids)
		},
		// 校区列表
		schoolCampusIndex() {
			this.$api.setting.schoolCampusIndex().then(res => {
				if (res.data.code == 1) {
					this.schooleList = res.data.data.rows;
					this.schoolId = this.schooleList[0].id;
					this.getSemesterList()
				}
			});
		},
		// 获取学年列表
		getSemesterList() {
			this.$api.setting.getSemesterList({}).then(res => {
				if (res.data.code == 1) {
					let data = res.data.data;
					for (let i in data) {
						if (data[i].is_default == 2) {
							this.yearId = data[i].id;
						}
					}
					this.yearList = data;
					this.getXueqiList();
				}
			});
		},
		getXueqiList() {
			this.$api.setting
				.getXueQiList({
					year_id:this.yearId
				})
				.then(res => {
					if (res.data.code == 1) {
						this.xueqiList = res.data.data;
						for(let i in this.xueqiList){
							if(this.xueqiList[i].is_default==2){
								this.xueqiId = this.xueqiList[i].id
							}
						}
						this.gradeData()
					}
				});
		},
		// 年级列表
		gradeData(){
			this.$api.setting.getGradeList({filter: JSON.stringify({'school_campus_id': this.schoolId})}).then(res=>{
				if(res.data.code==1){
					this.gradeList = res.data.data.rows;
					this.getJobList()
				}
			})
		},
		gradeChange(){
			this.getJobList()
			this.$api.station.getGradeDiscipline({id:this.gradeId, school_campus_id: this.schoolId}).then(res=>{
				if(res.data.code==1){
					let data = res.data.data;
					let arr = [];
					for(let i in data){
						data[i].checked = true;
						arr.push(data[i].id)
					}
					this.GradeDisciplineList = data;
					this.grades = arr;
				}
			})
			
		},
		schoolChange(e){
			window.localStorage.setItem("campusid",e);
			this.gradeId = '';
			this.getSemesterList()
		},
		yearChange(){
			this.xueqiId = '';
			this.getXueqiList()
		},
		weeksChange(index){
			if(this.GradeDisciplineList[index].checked){
				this.GradeDisciplineList[index].checked = false;
			}else{
				this.GradeDisciplineList[index].checked = true;
			}
			let arr = [];
			for(let i in this.GradeDisciplineList){
				if(this.GradeDisciplineList[i].checked){
					arr.push(this.GradeDisciplineList[i].id)
				}
			}
			this.GradeDisciplineList = this.GradeDisciplineList;
			this.grades = arr;
		},
		// 添加岗位
		submitaddJob(){
			this.$api.station.addJob({
				school_campus_id: this.schoolId,
				grade_id:this.gradeId,
				name:this.jobname,
				job_type:1,
				school_discipline_id:this.grades
			}).then(res=>{
				if(res.data.code==1){
					this.$message.success('添加成功');
					this.jobname = '';
					this.gradeChange()
					this.addShow = false;
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		// 编辑岗位
		submiteditJob(){
			this.$api.station.editJob({
				school_campus_id: this.schoolId,
				grade_id:this.gradeId,
				name:this.jobname,
				job_type:1,
				school_discipline_id:this.grades,
				id:this.stationId
			}).then(res=>{
				if(res.data.code==1){
					this.$message.success('编辑成功');
					this.jobname = '';
					this.gradeChange()
					this.editorShow = false;
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		closeaddjob(){
			this.jobname = '';
			this.gradeChange()
			this.addShow = false;
			this.editorShow = false;
		},
		handleClose(done){
			this.jobname = '';
			this.gradeChange()
			done()
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
		},
		twacherTap(index) {
			this.teacherIndex = index;
		},
		// 编辑
		editor(row) {
			this.jobname = row.name;
			this.stationId = row.id;
			this.editorShow = true;
		},
		// 配置
		deploy(id,name) {
			this.$router.push({
			    path:"/stationDetails",
			    query:{
			          id:id,
					  gradeId:this.gradeId,
					  title:name
			    }
			})
		},
		// 新增
		add() {
			this.addShow = true;
		},
		// 岗位列表
		getJobList(){
			let data = {
				'grade_id': this.gradeId,
				'school_campus_id': this.schoolId
			}
			if(this.keyword){data.name = this.keyword}
			this.$api.station.getJobList({filter: JSON.stringify(data)}).then(res=>{
				if(res.data.code==1){
					this.jobList = res.data.data.rows;
					this.total = res.data.data.total;
				}
			})
		},
		sousuo(){
			this.getJobList()
		},
		// 删除岗位
		delstaton(){
			let _this = this;
			if(this.ids.length){
				this.$confirm('确定要删除？','提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(()=>{
					this.$api.station.delJob({
						id:this.ids
					}).then(res=>{
						if (res.data.code == 1) {
							_this.$message.success('删除成功');
							_this.getJobList();
						} else {
							_this.$message.error(res.data.msg);
						}
					})
				})
			}else{
				this.$message.error('请选择要删除的岗位')
			}
		},
	}
};
</script>

<style lang="scss">
#paike_index {
	.content {
		.nav-tab-bar-box {
			background-color: #ffffff;
			padding: 0.62vw;
			margin-top: 1.04vw;
			.nav-tab-bar {
				.nav-tab-item {
					color: #6e7a87;
					padding: 0 0.78125vw;
					border-right: 1px solid #f3f3f3;
					cursor: pointer;
					-webkit-touch-callout: none;
					-webkit-user-select: none;
					-khtml-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}
				div:last-child {
					border-right: none;
				}
				.active {
					font-size: 0.833vw;
					color: #63acff;
					position: relative;
					font-weight: bold;
				}
				.active::after {
					position: absolute;
					content: '';
					height: 2px;
					left: 0.78125vw;
					right: 0.78125vw;
					background-color: #63acff;
					bottom: -0.7vw;
				}
			}
		}
		.shaixuan {
			background-color: #ffffff;
			padding: 10px 30px;
			margin-top: 10px;
		}
		.table-box {
			background-color: #ffffff;
			padding: 0 20px;
			margin-top: 20px;
			height: 72vh;
			.el-table th > .cell,
			.el-table .cell {
				overflow: hidden; /*超出部分隐藏*/
				white-space: nowrap; /*不换行*/
				text-overflow: ellipsis; /*超出部分文字以...显示*/
			}
			.el-table thead {
				background: #fafafa !important;
			}
			.el-table th {
				padding: 10px 5px !important;
				background: #fafafa !important;
			}
			.el-table::before {
				left: 0;
				bottom: 0;
				width: 100%;
				height: 0;
			}
			.el-dialog__header {
				padding: 15px;
				font-size: 14px;
				border-bottom: 1px solid #eeeeee;
				.el-dialog__title {
					font-size: 16px;
				}
			}
			.editorBox {
				padding: 0 20px;
				.div {
					padding: 10px 0 20px 0;
					.input-lebal {
						font-weight: bold;
						padding-right: 10px;
						span {
							color: red;
							font-weight: 100;
							margin-left: 3px;
						}
					}
					.weeks {
						display: flex;
						flex-wrap: wrap;
						div {
							background-color: #f3f3f3;
							padding: 6px 13px;
							margin-right: 10px;
							border-radius: 5px;
							margin-bottom: 10px;
							cursor: pointer;
						}
						div:nth-child(5n) {
							margin-right: 0;
						}
						.active{
							color: #409EFF;
						}
					}
				}
				.weeks{
					display: flex;
					flex-wrap: wrap;
					div{
						background-color: #F3F3F3;
						padding: 6px 13px;
						margin-right: 10px;
						border-radius: 5px;
						margin-bottom: 10px;
					}
					div:nth-child(5n){
						margin-right: 0;
					}
				}
			}
			.mergeBox {
				.table_box{
					margin-top: 15px;
					border-top: 1px solid #EEEEEE;
					height: 50vh;
					overflow-y: scroll;
				}
			}
			.el-dialog__body{
				padding: 15px 20px;
			}
		}
		.el-table__column-filter-trigger{
			.el-icon-arrow-down:before{
				content: '\E790';
				font-size: 16px;
			}
		}
	}
}
</style>
